@import "~antd/dist/antd.css";

.title {
  display: inline-block;
  font-size: 19px;
  font-weight: bold;
  padding-top: 10px;
  color: #666;
}

.app {
  display: grid;
  grid-template-areas: "header";
  grid-gap: 0px;
}

.subPage {
  display: grid;
  grid-template-areas: "sidemenu main";
  grid-template-columns: 275px 1fr;
  grid-gap: 2px;
  align-content: left;
  margin: 0px;
}

@media (max-width: 768px) {
  .subPage {
    display: grid;
    grid-template-areas:
      "sidemenu"
      "main";
    grid-template-columns: auto auto;
  }
}

.header {
  background: #f8f8f8;
  grid-area: header;
  padding-right: 30px;
  padding-left: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 52px;
  z-index: 100;
}

.sidemenu {
  width: 275px;
  grid-area: sidemenu;
  border-right-style: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  margin: 0px, 0px, 0px, 10px;
}

.subButtons {
  margin-right: 5px;
}

.main {
  grid-area: main;
}
.myLabel {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin: 3px;
  padding-top: 20px;
}

.myLabelTop {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin: 3px;
  padding-top: 3px;
}

.greenBox {
  border: 3px solid #00ff00;
  margin: 3px;
  padding: 3px;
  border-radius: 8px;
}

.redBox {
  border: 3px solid #ff0000;
  margin: 3px;
  padding: 3px;
  border-radius: 8px;
}

.sideNavItems {
  margin: 10px;
}

.paddingThirtyTop {
  padding-top: 10px;
  padding-left: 9px;
}

.imageSubLine {
  margin: 3px;
}

.subMenu {
  display: flex;
}

.disabledSubMenuItem {
  color: #999999;
  margin: 0px;
  padding: 20px;
}

.subMenuItem {
  margin: 0px;
  padding: 20px;
}
.subMenuItem:hover {
  background-color: #f8f8f8;
}
.ant-menu-horizontal {
  background-color: #f8f8f8;
}

.borderedContainer {
  width: 400px;
  height: 350px;
  /*border: 2px;
  border-style: solid;
  border-color: #666666; */
  border-radius: 16px;
  padding: 50px;
  background: white;
  margin: 0px auto 0px auto;
}

.loginContainer {
  width: 400px;
  height: 400px;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  border-radius: 16px;
  margin: 0px auto 0px auto;
  /* border:1px solid #bbb; */
  box-shadow: 4px 4px #ddd;
}

.signupContainer {
  width: 400px;
  height: 440px;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  border-radius: 16px;
  margin: 0px auto 0px auto;
  box-shadow: 4px 4px #ddd;
}

.selectedModel {
  background-color: #c3e5ff;
}
.modelBox:hover {
  background-color: #f8f8f8;
}

.mybg {
  min-height: 100vh;
  padding-top: 100px;
  background: #e9ecef;
  /* background: linear-gradient(180deg, #00d2ff 0%, #3a47d5 100%); */
  /* background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%); */
}

.disabled {
  color: #ccc
}